const style = {
    root: {
      display: 'flex',
      "& .MuiInputBase-root":{
        fontSize: '0.82rem'
      },
      "& .MuiTableCell-root":{
        padding: "7px"
      },
      "& .MuiTableCell-head":{
        fontWeight: "bold"
      }
      },
      buttonGrid:{
        justifyContent: 'space-between',
        display: 'flex',
        "& .MuiInputBase-root":{
         height: '40px'
        }
      },
      paper: {
        marginTop: '20px'
      },
      buttonPrim:{
        color: '#003496',
        border: '1px solid #003496',
        backgroundColor: 'white'
      },
      labelInput: {
       // fontWeight: 'bold',
        color: '#003496'
      },
      labelInputFocused:{
        color: '#003496 !important'
      }, 
      inputFocused: {
         backgroundColor: '#F1F1F1'
      }, 
    field: {
        margin: '20px'
    },
    form: {
      width: '40vw'
    },
    multi:{
      marginTop: '15px'
    },
    image:{
      marginBottom: '25px',
      width: '48vw'
    },
    projects: {
      marginBottom: '50px'
    },
    environments: {
     display: 'flex',
     justifyContent: 'center',
     marginBottom: '30px'
    },
    legend: {
      color: '#003496',
      marginBottom: '15px'
    },
    content: {
      alignContent: 'start',
      display: 'flex',
      paddingLeft: '20px'
    },
    button:{
      marginLeft: '25px',
      height: '40px'
    },
    projectText:{
      fontSize: '10px'
    }    
}

export default style