import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import Loader from "../../components/Loader/index";
import Flexmonster from '../../components/Flexmonster';
import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const useStyles = makeStyles((theme) => (style));

export default function Template() {

  const classes = useStyles();
  const [showLoader, setShowLoader] = React.useState(false);
  const [data, setData] =React.useState([])

  React.useEffect(() =>  {
    getBuildingBlocks()
  }, []);

  const getBuildingBlocks = async () => {
    
    var buildingBlocksResp = await API.graphql({ query: queries.listBlocks });
    var buildingBlocks = buildingBlocksResp.data.listBlocks.items
    var flatblocks = []

    for (var i = 0; i < buildingBlocks.length; i++){
      for (var j = 0; j < buildingBlocks[i].versions.length; j++){
        var bbVersion = JSON.parse(buildingBlocks[i].versions[j])
        // if (bbVersion.status === 'Default') {
          buildingBlocks[i].version = bbVersion.version
          buildingBlocks[i].repositoryType = bbVersion.repositoryType
          buildingBlocks[i].repository = bbVersion.repository
          buildingBlocks[i].readme = bbVersion.readme
          buildingBlocks[i].instructions = bbVersion.instructions
          buildingBlocks[i].status = bbVersion.status
          buildingBlocks[i].cloud = bbVersion.cloud
          buildingBlocks[i].projects = bbVersion.projects
          if (buildingBlocks[i].projects.length > 0){
          for (var k = 0; k < bbVersion.projects.length; k++){
            var item = {}
            item.name = buildingBlocks[i].name
            item.year = buildingBlocks[i].year
            item.family = buildingBlocks[i].family
            item.hive = buildingBlocks[i].hive
            item.project = bbVersion.projects[k].id
            item.issue = bbVersion.projects[k].issue 
            item.costs = parseFloat(bbVersion.projects[k].value) 
            item.hours = parseFloat(bbVersion.projects[k].hours) 
            item.comments = bbVersion.projects[k].comments 
            item.activated = bbVersion.projects[k].activated 
            flatblocks.push(item)
          } } else {
            var item = {}
            item.name = buildingBlocks[i].name
            item.year = buildingBlocks[i].year
            item.family = buildingBlocks[i].family
            item.hive = buildingBlocks[i].hive
            item.project = ''
            item.issue = ''
            item.costs = 0
            item.hours = 0 
            item.comments = ''
            item.activated = false
            flatblocks.push(item)
          }
        // } 
      }
    }

    setData(flatblocks)
    setShowLoader(false)
  }

  return (
    <>
     <Loader show = {showLoader}/>
        <Flexmonster data= {data}></Flexmonster>
    </>
  )
}
