/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBlock = /* GraphQL */ `
  mutation CreateBlock(
    $input: CreateBlockInput!
    $condition: ModelblockConditionInput
  ) {
    createBlock(input: $input, condition: $condition) {
      id
      name
      description
      family
      hive
      type
      year
      thumbnail
      license
      versions
      createdAt
      updatedAt
    }
  }
`;
export const updateBlock = /* GraphQL */ `
  mutation UpdateBlock(
    $input: UpdateBlockInput!
    $condition: ModelblockConditionInput
  ) {
    updateBlock(input: $input, condition: $condition) {
      id
      name
      description
      family
      hive
      type
      year
      thumbnail
      license
      versions
      createdAt
      updatedAt
    }
  }
`;
export const deleteBlock = /* GraphQL */ `
  mutation DeleteBlock(
    $input: DeleteBlockInput!
    $condition: ModelblockConditionInput
  ) {
    deleteBlock(input: $input, condition: $condition) {
      id
      name
      description
      family
      hive
      type
      year
      thumbnail
      license
      versions
      createdAt
      updatedAt
    }
  }
`;
