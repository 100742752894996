import React, {useReducer} from "react";

const Context = React.createContext({});

const ACTIONS = {
  SHOW_ALERT: "change_alert",
  HIDE_ALERT: "hide_alert"
};

// Definimos la acción que se debe realizar para cada tipo de acción
const ACTIONS_REDUCERS = {
  [ACTIONS.SHOW_ALERT]: (state, action) => {
    return {
      severity: action.payload.severity || "error",
      message: action.payload.message || "",
      visible: true
    }
  },
  [ACTIONS.HIDE_ALERT]: (state, action) => {
    return {
      severity: "error",
      message: "",
      visible: false
    }
  }
};

const reducer = (state, action) => {
  // Si action reducer no existe devolvemos el state inicial para evitar posibles errores
  const actionReducer = ACTIONS_REDUCERS[action.type];
  return actionReducer ? actionReducer(state, action) : state;
};

export function AlertProvider({children}) {

  const [{severity, message, visible}, dispatch] = useReducer(reducer, {
    severity: "info",
    message: "",
    visible: false
  });

  return <Context.Provider
    value={{
      showAlert: ({severity, message}) => {
        dispatch({type: ACTIONS.SHOW_ALERT, payload: {severity, message}});
      },
      hideAlert: () => {
        dispatch({type: ACTIONS.HIDE_ALERT});
      },
      severity,
      message,
      visible
    }}>
    {children}
  </Context.Provider>
}

export default Context;
