const style = {
    root: {
        "& #fm-toolbar-wrapper #fm-toolbar li.fm-divider":{
          borderRight: 'none'
        },
        "& .fm-charts-color-1": {
          fill: '#299891 !important'
        },
        "& .fm-charts-color-2" : {
          fill: '#BDBDBD !important'
        },
        
        "& .fm-charts-color-3" : {
          fill: '#008FFB !important'
        },
        
        "& .fm-charts-color-4" : {
          fill: '#00E396 !important'
        },
        
        "& .fm-charts-color-5" : {
          fill: '#949be8 !important'
        },
        
        "& .fm-charts-color-6" : {
          fill: '#cbdcf2 !important'
        },
        
        "& .fm-charts-color-7" : {
          fill: 'none !important'
        },

        "& #fm-toolbar-wrapper #fm-toolbar li.fm-divider":{
          margin: 0,
          visibility: 'hidden'
        },
        backgroundColor: 'white'       

      },
      chart:{
        marginTop: '10px',
        marginLeft: '20px',
        marginRight: '20px',
        height: '550px',
      },
      grid: {
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      },
      carousel: {
        backgroundColor: 'white',
        margin: '10px'
      },
      pivot: {
        margin: '10px',
        minWidth: '350px',
      }
}

export default style