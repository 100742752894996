import React, {useState} from "react";

const Context = React.createContext({});
const languages = [
  "zh-CN",
  "es-ES",
  "en-EN"
];

export function LanguageProvider ({children}) {
  const [language, setLanguage] = useState(languages.find(language => language.indexOf(navigator.language) > -1) || "en-EN");

  return <Context.Provider value={{language, setLanguage}}>
    {children}
  </Context.Provider>
}

export default Context;
