import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Home from '@material-ui/icons/HomeTwoTone'
import Widgets from '@material-ui/icons/WidgetsTwoTone'
import Report from '@material-ui/icons/Assessment'
import ListCSS from './style';


const useStyles = makeStyles((theme) => (ListCSS));

export default function DrawerList({parentCallback}) {

const classes = useStyles();
const [selected, setSelected] = React.useState(0);

const handleSelected = (index) => {
 setSelected(index);
 parentCallback(index)
};

return (

    <div>
        <List>
          {['Building blocks', 'Report'].map((text, index) => (
            <MenuItem button key={text} 
            classes={{ root: classes.root, selected: classes.selected }}
            onClick={() => handleSelected(index)}
            selected = {selected===index}>
            <ListItemIcon className={selected === index? classes.iconSelected : classes.icon}>
            { 
              //index === 0 ? < Home/> : 
              index === 0 ? < Widgets/> : 
              index === 1 ? <Report/>:
             < Home/>}
            </ListItemIcon>
            <ListItemText primary={text} />
            </MenuItem>
        ))}
        </List>

    </div> 
)
}