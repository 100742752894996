/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://vhr2rrptdjemjo6dnv4gq3yxwi.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:37a179e8-a41d-4171-9d06-2ab3e57158eb",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_o6M9Avlbs",
    "aws_user_pools_web_client_id": "7v5tv3076bbsnhon3s1ur47q56",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "digitalassets94247-pro",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
