import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import { forwardRef } from 'react';
import BlockForm from "../../components/BlockForm/index";
import {Edit,CheckOutlined,ClearOutlined, DeleteOutlineOutlined, ChevronRightOutlined, SaveAltOutlined, FilterListOutlined, FirstPageOutlined, LastPageOutlined, ChevronLeftOutlined, SearchOutlined, ArrowDownwardOutlined, RemoveOutlined, ViewColumnOutlined } from '@material-ui/icons/';
import MaterialTable from "material-table";
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => (style));

const tableIcons = {
  Check: forwardRef((props, ref) => <CheckOutlined {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <ClearOutlined {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutlineOutlined {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRightOutlined {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAltOutlined {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterListOutlined {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPageOutlined {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPageOutlined {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRightOutlined {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeftOutlined {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <ClearOutlined {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <SearchOutlined {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownwardOutlined {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <RemoveOutlined {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumnOutlined {...props} ref={ref} />)
};

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} variant="outlined" {...props} ref={ref} />);

export default function Table(props) {

    const classes = useStyles();
    const [data, setData] =React.useState([])
    const [columns, setColumns] = React.useState([]);
    const [visibleColumns, setVisibleColumns] = React.useState(true);
    const [actionsEdit, setActionsEdit] = React.useState([]);
    const [actionsDelete, setActionsDelete] = React.useState();

    React.useEffect(() =>  {

      setColumns(props.columns)

      if (props.data){
      setData(props.data)
      }

      if (props.edit){
        setActionsEdit([{
          icon:  () => <Edit/>,
          tooltip: 'Edit',
          onClick: (event, rowData) => {
            new Promise((resolve) => {
            handleEdit(rowData, resolve)
           })
          }
        }])
      }

      if (props.delete){
      setActionsDelete({
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            handleRowDelete(oldData, resolve)
          }),
        })
      }
    }, [props.data]);

    const handleEdit = (data) => {
      props.onActionClick({
        type: 'edit',
        data: data
      })
    }  

    const handleRowDelete = (oldData, resolve) => {
      resolve()
      props.onActionClick({
        type: 'delete',
        data: oldData
      })
    }

    const handleRowClick = (data) => {
      props.onActionClick({
        type: 'row',
        data: data
      })
    }

    const handleVisible = (value) => {
      setVisibleColumns(value)
    }

  return (
    <div className={classes.root}>
        <MaterialTable 
              title=""
              columns={columns}
              actions={actionsEdit}
              data={data}
              onRowClick={props.rowClick?handleRowClick:null}
              icons={tableIcons}
              detailPanel={rowData => {
                return (
                  <BlockForm data = {rowData} hide={handleVisible}/>
                )
              }}
              localization={{
                body: {
                  editRow:{
                    deleteText: 'Do you want to delete the selected item?'
                  }
                }
              }}
              options = {
                {pageSize: props.size,
                addRowPosition: 'first',
                exportButton: true,
                columnsButton: true,
                header: visibleColumns,
                filtering: props.filtering,
                padding: 'dense',
                actionsColumnIndex: 90,
                pageSizeOptions:[10,20,50,100,500],
                headerStyle: {
                  fontSize: 16,
                  fontWeight: 'bold',
                },
                rowStyle: {
                  fontSize: 16
                },
                actionsCellStyle: {}
              }
              }
              editable={actionsDelete}
        />
   </div>
  )
}
