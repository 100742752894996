import {makeStyles} from '@material-ui/core/styles';

const LoaderCSS = makeStyles((theme) => ({

    root: {
      paddingTop: '15%',
      position: 'fixed',
      top:50,
      right:0,
      bottom:0,
      left:0
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}))

export default LoaderCSS