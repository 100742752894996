const Config = [
       {
            id: 'name',
            label: 'Building block name',
            type: 'multiline',
            mandatory: true,
            disabled: false,
            values: []
        }, 
        {
            id: 'description',
            label: 'Building block description',
            type: 'multiline',
            mandatory: true,
            disabled: false,
            values: [] 
        },  
        {
            id: 'year',
            label: 'Year',
            type: 'multiline',
            mandatory: true,
            disabled: false,
            values: [] 
        },  
        {
            id: 'family',
            label: 'Family',
            type: 'select',
            mandatory: true,
            disabled: false,
            values: [
                {
                    id:"AI",
                    value: "AI"
                    }, 
                    {
                    id:"Cognitive",
                    value: "Cognitive"
                    },
                    {
                    id:"Process",
                    value: "Process"
                    },
                    {
                    id:"SAP",
                    value: "SAP"
                    },                    {
                    id:"Methodologies",
                    value: "Methodologies"
                    }
            ] 
        },
        {
            id: 'type',
            label: 'Type',
            type: 'select',
            mandatory: true,
            disabled: false,
            values: [
                {
                    id:"ABAP",
                    value: "ABAP Package"
                    }, 
                {
                    id:"Algorithm",
                    value: "Algorithm"
                    }, 
                    {
                    id:"API",
                    value: "API"
                    },
                    {
                    id:"Container",
                    value: "Container"
                    },
                    {
                    id:"Library",
                    value: "Library"
                    },
                    {
                        id:"Process",
                        value: "Process"
                        },
                        {
                        id:"Program",
                        value: "Program"
                        },
                        {
                            id:"UI",
                            value: "UI"
                            },
                        {
                            id:"Workflow",
                            value: "Workflow"
                            },
            ] 
        },
        {
            id: 'hive',
            label: 'Hive',
            type: 'select',
            mandatory: true,
            disabled: false,
            values: [
                {
                    id:"AI4Business",
                    value: "AIBusiness"
                    }, 
                    {
                    id:"Cognitive",
                    value: "Cognitive"
                    },
                    {
                    id:"Desarrollo de Soluciones",
                    value: "Desarrollo de Soluciones"
                    },
                    {
                    id:"Experience management",
                    value: "Experience management"
                    },
                    {
                    id:"Transformación Digital",
                    value: "Transformación Digital"
                    }, 
                    {
                    id:"ThinkSolve",
                    value: "ThinkSolve"
                    },
                    {
                    id:"Artemis",
                    value: "Artemis"
                    }
            ] 
        },
        {
            id: 'repositoryType',
            label: 'Repository',
            type: 'select',
            mandatory: true,
            disabled: false,
            values: [
                {
                    id:"Bitbucket",
                    value: "Bitbucket"
                    }, 
                    {
                    id:"CodeArtifact",
                    value: "Code Artifact"
                    },
                    {
                    id:"ECR",
                    value: "ECR"
                    },
                    {
                    id:"Others",
                    value: "Others"
                    }
            ] 
        },
        {
            id: 'repository',
            label: 'Repository URL',
            type: 'multiline',
            mandatory: true,
            disabled: false,
            values: []
        }, 
        {
            id: 'readme',
            label: 'Readme (how to deploy)',
            type: 'multiline',
            mandatory: true,
            disabled: false,
            values: []
        }, 
        {
            id: 'instructions',
            label: 'Instructions (how to consum)',
            type: 'multiline',
            mandatory: true,
            disabled: false,
            values: []
        }, 
        {
            id: 'status',
            label: 'Version status',
            type: 'select',
            mandatory: true,
            disabled: false,
            values: [
                {
                    id:"Active",
                    value: "Active"
                    },
                    {
                    id:"Default",
                    value: "Default"
                    },
                    {
                    id:"Deprecated",
                    value: "Deprecated"
                    },
                    {
                    id:"Innovation",
                    value: "Innovation"
                    }
            ] 
        }

    ]


export default Config
