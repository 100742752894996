const style = {
    root: {
      "& .MuiPaper-root":{
        padding: '20px'
      },
      },
    buttonGrid:{
      textAlign: 'end'
    },
    button:{
      marginBottom: '15px',
      marginLeft: '20px'
    }

}

export default style