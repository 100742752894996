import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import * as WebDataRocksReact from 'react-webdatarocks';
import Loader from "../Loader/index";
import 'webdatarocks/webdatarocks.css'
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => (style));

// Para cambiar entre webdatarocks y flexmonster hay que:
// - Substituir el objeto WebDataRocksReact por FlexmonsterReact
// - Substituir las referencias webdatarocks por flexmonster
// - Substituir la refencia wdr del custom toolbar por fm

export default function Flexmonster(props) {

  const classes = useStyles();
  const ref = React.useRef();
  const [showLoader, setShowLoader] = React.useState(false);

  React.useEffect(() =>  {
        console.log(props.data)
        ref.current.webdatarocks.setReport({ 
          dataSource: { 
            data: props.data?props.data:{}
          }, 
          "slice":{
            "reportFilters":[
            {
            "uniqueName":"year",
            "filter":{
            "members":[
            "year.Q1.2022"
            ]
            }
            }
            ],
            "rows":[
            {
            "uniqueName":"family"
            },
            {
            "uniqueName":"hive"
            },
            {
            "uniqueName":"name"
            },
            {
            "uniqueName":"project"
            },
            {
            "uniqueName":"issue"
            },
            {
            "uniqueName":"activated"
            },
            {
            "uniqueName":"comments"
            }
            ],
            "columns":[
            {
            "uniqueName":"Measures"
            }
            ],
            "measures":[
            {
            "uniqueName":"hours",
            "aggregation":"sum"
            },
            {
            "uniqueName":"costs",
            "aggregation":"sum"
            }
            ],
            "expands":{
            "rows":[
            {
            "tuple":[
            "family.AI",
            "hive.AI4Business"
            ]
            },
            {
            "tuple":[
            "family.AI"
            ]
            },
            {
            "tuple":[
            "family.AI",
            "hive.AI4Business"
            ]
            },
            {
            "tuple":[
            "family.AI",
            "hive.AI4Business",
            "name.Azure ML Pipeline for train and deploy a predictor"
            ]
            },
            {
            "tuple":[
            "family.AI",
            "hive.AI4Business",
            "name.Azure ML Pipeline for train and deploy a predictor",
            "project.VOD03"
            ]
            },
            {
            "tuple":[
            "family.AI",
            "hive.AI4Business",
            "name.Azure ML Pipeline for train and deploy a predictor",
            "project.VOD03",
            "issue.VODPEPPI-10"
            ]
            },
            {
            "tuple":[
            "family.AI",
            "hive.AI4Business",
            "name.Azure ML Pipeline for train and deploy a predictor",
            "project.VOD03",
            "issue.VODPEPPI-10",
            "activated.undefined"
            ]
            }
            ]
            },
            "flatOrder":[
            "year",
            "family",
            "hive",
            "name",
            "project",
            "issue",
            "activated",
            "comments",
            "hours",
            "costs"
            ]
            },
            "options":{
            "grid":{
            "type":"flat",
            "showTotals":"off"
            }
            },
            "formats":[
            {
            "name":"",
            "thousandsSeparator":".",
            "decimalSeparator":",",
            "decimalPlaces":2,
            "nullValue":"",
            "textAlign":"right",
            "isPercent":false
            }
            ]
            }
      )
     }, [props.data]);


  return (
    <div className = {classes.root}>
      <Loader show = {showLoader}/>
        <div className = {classes.pivot}>
          <Paper>
          <WebDataRocksReact.Pivot 
                ref={ref}
                toolbar={true}
                width="100%"
                height="800px"
            />
            </Paper>
         </div>
    </div>
  )
}
