import {makeStyles} from '@material-ui/core/styles';
import {Storage} from 'aws-amplify';
import style from './style';
import React from 'react';
import Loader from "../Loader/index";
import {Button, Paper} from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import {
  IconButton,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Toolbar,
  FormLabel,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import {Add, Delete} from '@material-ui/icons';
import FormControl from '@material-ui/core/FormControl';
import {DropzoneArea} from "material-ui-dropzone";
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Config from './config'
import { API, graphqlOperation  } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import {createBlock, updateBlock} from '../../graphql/mutations';
import { setDefaults } from 'react-i18next';

const useStyles = makeStyles((theme) => (style));

export default function Form(props) {

  const classes = useStyles();
  const [showLoader, setShowLoader] = React.useState(false);
  const [showVersion, setShowVersion] = React.useState(true);
  const [editVersionDisabled, setEditVersionDisabled] = React.useState(true);
  const [formData, setFormData] = React.useState({});
  const [values, setValues] = React.useState([]);
  const [defaultV, setDefaultV] = React.useState('');
  const [versions, setVersions] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [fileList, setFileList] = React.useState([]);
  const [AWS, setAWS] = React.useState(false);
  const [IBM, setIBM] = React.useState(false);
  const [SAP, setSAP] = React.useState(false);
  const [Azure, setAzure] = React.useState(false);
  const [OnPremise, setOnPremise] = React.useState(false);
  const forceUpdate = React.useReducer(bool => !bool)[1];

  React.useEffect(() =>  {

    if (props.data.versions){
      parseVersions(props.data.versions)
      setShowVersion(false)
    } 
  }, [props.data]);

  const setCheckboxes = (data) => {
    setIBM(data?data.includes("IBM"):false)
    setAWS(data?data.includes("AWS"):false)
    setAzure(data?data.includes("Azure"):false)
    setSAP(data?data.includes("SAP"):false)
    setOnPremise(data?data.includes("Local"):false)
  }

  const setProjects = (data) => {
    var valueAux = []
    for (var i = 0; i < data.length; i++){

      valueAux.push({
        id: data[i].id,
        issue: data[i].issue,
        value: data[i].cost,
        hours: data[i].hours,
        comments: data[i].comments,
        activated: data[i].activated
      })
    }
    setValues(valueAux)

  }

  const parseVersions = (data) => {

      const vers = []
      var item = {}
      var defaultItem = {}

      // Parseamos y cogemos la versión default
      for (var i = 0; i < data.length; i++){
       item = JSON.parse(data[i])
       vers.push(item)
       if (!defaultV && item.status === 'Default'){
         defaultItem = item
         setDefaultV(item.version)
       }
      }
      // Si no hay default, cogemos la última
      if (!defaultItem){
        defaultItem = item
        setDefaultV(item.version)
      }  

      // Guardamos la versión default
      setVersions(vers)

      var key = props.data.id + '/' + defaultItem.version + '/'
      Storage.list(key)
      .then(result => {
          var fileAux = []
          
          for (var i = 0; i < result.length; i++){
            if (result[i].size !== 0){
              fileAux.push({
                key: result[i].key,
                id: result[i].key.split(key)[1],
                size: Math.round((result[i].size / 1000)) + ' kb',
                modified: result[i].lastModified
              })
            }
          }
         setFileList(fileAux)
      })
      .catch(err => console.log(err));
      
      // Construimos el registro inicia del formulario
      const initialData = props.data
      Object.assign(initialData, defaultItem)
      setFormData(initialData)
      setCheckboxes(initialData.cloud)
      if (initialData.projects){
        setProjects(initialData.projects)
      }

  }

  const handleSave = async () => {

    setShowLoader(true)

    const versionsParsed = manageVersions(formData.id)

    const block = {  
      //header
      name: formData.name,
      description: formData.description,
      family: formData.family,
      year: formData.year, 
      type: formData.type,
      hive: formData.hive,
      thumbnail: '',
      license: false,
      //positions
      versions: versionsParsed
    }
    console.log(block)
    var id 
    if (formData.id){
        block.id =  formData.id
        var respUp = await API.graphql(graphqlOperation(updateBlock, {input: block}));
        id = respUp.data.updateBlock.id
     
    } 
    else {
        var respCr = await API.graphql(graphqlOperation(createBlock, {input: block}));
        id = respCr.data.createBlock.id
    }

    const keys = await saveFiles(id, formData.version?formData.version:'1.0')
    props.onReturn()
  }

  const handleChangeEnvironment = async (event) => {
    if (event.target.name === 'AWS'){
      setAWS(AWS?false:true)
    }
    else if (event.target.name === 'IBM'){
      setIBM(IBM?false:true)
    }
    else if (event.target.name === 'Local'){
      setOnPremise(OnPremise?false:true)
    }
    else if (event.target.name === 'SAP'){
      setSAP(SAP?false:true)
    }
    else if (event.target.name === 'Azure'){
      setAzure(Azure?false:true)
    }

  }

  const handleChangeField = (index, field, value) => {
  
    var valueAux = values
     valueAux[index][field] = value
     setValues(valueAux)
     forceUpdate()
  };

  const addHeader = () => {
     var valueAux = values
     valueAux.push({
       id: '',
       value: '',
       hours: '',
       comments: '',
       activated: false
     })
     setValues(valueAux)
     forceUpdate()
  }

  const removeHeader = (id) => {
    var valueAux = values
    valueAux.splice(id, 1)
    setValues(valueAux)
    forceUpdate()  
  };

  const manageVersions = (id) => {

    //Recuperamos los entornos
    var environments = []
    if (AWS) {environments.push('AWS')}
    if (IBM) {environments.push('IBM')}
    if (SAP) {environments.push('SAP')}
    if (Azure) {environments.push('Azure')}
    if (OnPremise) {environments.push('Local')}
 
    // Recuperamos los proyectos
    var projects = [] 
    for (var i = 0; i < values.length; i++){
      var item = {
        id:    values[i].id,
        issue: values[i].issue,
        cost:  values[i].value,
        hours: values[i].hours,
        comments: values[i].comments,
        activated: values[i].activated
      }
      projects.push(item)
    }

    //Guardamos las versiones no modificadas y la actualizada
    var versionsP = []

    if (versions.length > 0){
        for (var i = 0; i < versions.length; i ++) {
          if (versions[i].version === defaultV || versions[i].version === '') {
          versionsP.push(JSON.stringify(
            {
            version:formData.version,
            repositoryType: formData.repositoryType,
            repository: formData.repository,
            readme: formData.readme,
            instructions: formData.instructions,
            status: formData.status,
            cloud: environments,
            projects: projects
            }))
          } else {
            versionsP.push(JSON.stringify(versions[i]))
          }
        }
    } else {
      versionsP.push(JSON.stringify(
        {
        version: '1.0',
        repositoryType: formData.repositoryType,
        repository: formData.repository,
        readme: formData.readme,
        instructions: formData.instructions,
        status: 'Default',
        cloud: environments,
        projects: projects
        }))
    }
    console.log(versionsP)
    return versionsP

  }

  const newVersion = () => {

    setShowVersion(true)
    setEditVersionDisabled(false)

    // Duplicamos los datos
    var versionsNew = Object.assign([], versions)
    versionsNew.push({
      version: '',
      repositoryType: formData.repositoryType,
      repository: formData.repository,
      readme: formData.readme,
      instructions: formData.instructions,
      status: formData.status
     }
    )

    setVersions(versionsNew)
    var formDataN = Object.assign({}, formData)
    formData.version = ''
    setFormData(formDataN)
  }

  const handleChanges = (event) => {
   
    var formDataNew = Object.assign({}, formData);
    if (event.target){
    formDataNew[event.target.id?event.target.id:event.target.name?event.target.name:''] = event.target.value
    }
    if (event.relativeKey){
      formDataNew.key = event.relativeKey  
    }

    // Si cambia la version la marcamos como default
    if (event.target.id && event.target.id === 'version'){
      setDefaultV(event.target.value)
    }
    setFormData(formDataNew);
    
  };

  const handleFiles = (file) => {
    if (file[0] !== undefined){
      setFiles(file)
    }
  }

  const removeFile = async (key) => {

    await Storage.remove(key)

    var fileAux = []

    for (var i = 0; i < fileList.length; i++){
      if (fileList[i].key !== key){
        fileAux.push(fileList[i])
      }
    }
    setFileList(fileAux)

  }

  const handleVersions = (event) => {

    var formDataNew = Object.assign({}, formData);

    for (var i = 0; i < versions.length; i++){
     
     if (versions[i].version === event.target.value) {

        formDataNew.version = versions[i].version
        formDataNew.repositoryType = versions[i].repositoryType
        formDataNew.repository = versions[i].repository
        formDataNew.readme = versions[i].readme
        formDataNew.instructions = versions[i].instructions
        formDataNew.status = versions[i].status

        setFormData(formDataNew)

        setCheckboxes(versions[i].cloud)
        if (versions[i].projects){
          setProjects(versions[i].projects)
        }
      } 

    }

    var key = props.data.id + '/' + formDataNew.version + '/'
    Storage.list(key)
    .then(result => {
        var fileAux = []
        console.log(result)
        for (var i = 0; i < result.length; i++){
          if (result[i].size !== 0){
            fileAux.push({
              key: result[i].key,
              id: result[i].key.split(key)[1],
              size: Math.round((result[i].size / 1000)) + ' kb',
              modified: result[i].lastModified
            })
          }
        }
       setFileList(fileAux)
    })
    .catch(err => console.log(err));


    setDefaultV(event.target.value)

  }

  const saveFiles = async (path, version) => {
    var keys = []

    for (var i = 0; i < files.length; i++){
      if (files[i].name){
        var key = path + '/' + version + '/' + files[i].name;
        try {
          await Storage.put(key, files[i], {
            level: 'public',
            progressCallback(progress) {
              console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          },
          });
          keys.push(key)
        } catch (error) {
            console.log('Error uploading file: ', error);
        }
      }  
    } 
    return keys
  }


  return (
    <>
     <Loader show = {showLoader}/>
     <div className={classes.buttonGrid}>
     <Button
        variant="contained"
        onClick={() => props.onReturn()}
        className={classes.buttonPrim}
        startIcon={<BackIcon />}
        >
        Back
      </Button>
      <Typography variant="h5">{(formData.id?'Update ':'Create ') + 'building block'}</Typography>
         <div className ={classes.buttonGrid}>
              {versions.length > 0?
                <div>
                  <FormControl variant="outlined" className={classes.formControl}>  
                  <InputLabel className= {classes.labelInput} id="versions">Versions</InputLabel>
                   <Select 
                     labelId={"versions"}
                     value={defaultV?defaultV:''}
                     name="versions"
                     label="Versions"
                     variant="outlined"
                     onChange={handleVersions}
                   >
                    {versions.map((item) => (
                     <MenuItem value={item.version}>{item.version + ' - ' + item.status}</MenuItem>
                     ))}
                   </Select>
                   </FormControl> 
                  <Button className = {classes.button}
                  variant="contained"
                  onClick={newVersion}
                  color="secondary"
                  startIcon={<AddIcon />}
                  >
                  Version
                </Button>
                </div>
              :null} 
      <Button className = {classes.button}
        variant="contained"
        onClick={handleSave}
        color="secondary"
        startIcon={<SaveIcon />}
        >
        Save
      </Button>
      </div>
      </div>
      <Paper className = {classes.paper}>
      <div className = {classes.root}>
        <div className = {classes.form}> 
        {showVersion === true?
            <FormGroup className= {classes.field}>
                    <TextField
                    required = {true}
                    variant="outlined"
                    multiline={true}
                    placeholder=''
                    id="version"
                    label="Version"
                    type="text"
                    disabled = {editVersionDisabled}
                    autoFocus={false}
                    fullWidth
                    onChange={handleChanges}
                    defaultValue = {formData.version?formData.version:'1.0'}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelInput,
                        focused: classes.labelInputFocused
                      }
                    }} 
                    InputProps={{
                      classes: {
                        root: classes.input,
                        focused: classes.inputFocused
                      }
                    }} 
                  />
                </FormGroup>:null}
                  {Config.map((item, index) => (
                  <FormGroup className= {classes.field}>
                    {(item.type === 'input' || item.type==='multiline')?
                    <TextField
                    required = {item.mandatory}
                    variant="outlined"
                    multiline={item.type==='multiline'}
                    placeholder=''
                    id={item.id}
                    label={item.disabled?'':item.label}
                    type="text"
                    disabled = {item.disabled}
                    autoFocus={false}
                    fullWidth
                    onChange={handleChanges}
                    defaultValue = {formData?formData[item.id]:''}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelInput,
                        focused: classes.labelInputFocused
                      }
                    }} 
                    InputProps={{
                      classes: {
                        root: classes.input,
                        focused: classes.inputFocused
                      }
                    }} 
                  />
                  :item.type === 'select'?
                  <FormControl variant="outlined" className={classes.formControl}>  
                  <InputLabel className= {classes.labelInput} id={"label" + index}>{item.label}</InputLabel>
                   <Select 
                     labelId={"label" + index}
                     value={formData[item.id]?formData[item.id]:''}
                     name={item.id}
                     label={item.label}
                     variant="outlined"
                     onChange={handleChanges}
                   >
                     {item.values.map((menuItem) => (
                     <MenuItem value={menuItem.id}>{menuItem.value}</MenuItem>
                     ))}
                   </Select>
                   </FormControl>
                    :<div></div>
                    }
                  </FormGroup>
                ))}
                </div>
                <div className={classes.multi}>
                <div className={classes.image}>
                    <div  className={classes.content}>
                    <Typography className = {classes.legend}>Environments</Typography>
                    </div>
                        <div className = {classes.environments}>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormGroup>
                            <div>
                         
                            <FormControlLabel
                              control={
                            <Checkbox checked={AWS} onChange={handleChangeEnvironment} name="AWS" />
                              }
                              label="AWS"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox checked={IBM} onChange={handleChangeEnvironment} name="IBM" />
                              }
                              label="IBM"
                            />
                         
                            <FormControlLabel
                              control={
                                <Checkbox checked={OnPremise} onChange={handleChangeEnvironment} name="Local" />
                              }
                              label="Local"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox checked={SAP} onChange={handleChangeEnvironment} name="SAP" />
                              }
                              label="SAP"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox checked={Azure} onChange={handleChangeEnvironment} name="Azure" />
                              }
                              label="Azure"
                            />
                          
                            </div>
                          </FormGroup>
                        </FormControl>
                        </div>  
                        <div className = {classes.projects}>
                     <Toolbar style={{justifyContent: "space-between"}}>
                          <Typography className = {classes.legend}>Projects</Typography>
                          <IconButton color={"secondary"}
                            onClick={addHeader}
                          > 
                            <Add/>
                          </IconButton>
                        </Toolbar>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{width: "2rem"}}/>
                                <TableCell style={{width: "40px"}}>{"Active"}</TableCell>
                                <TableCell style={{width: "70px"}}>{"Project"}</TableCell>
                                <TableCell style={{width: "120px"}}>{"Issue"}</TableCell>
                                <TableCell style={{width: "80px"}}>{"Cost(€)"}</TableCell>
                                <TableCell style={{width: "70px"}}>{"Hours"}</TableCell>
                                <TableCell>{"Comments"}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                values.map(({id, issue, value, hours, comments, activated},index) => (
                                  <TableRow key={issue}>
                                    <TableCell style={{width: "2rem", marginTop: "9px"}} component={IconButton}
                                               onClick={() => removeHeader(index)}
                                      >
                                      <Delete color={"secondary"}/>
                                    </TableCell > 
                                     <TableCell style={{width: "40px"}}>
                                      <Checkbox checked={activated} 
                                      onChange = {(event) => handleChangeField(index, 'activated', event.target.checked)}
                                      />
                                    </TableCell>
                                    <TableCell style={{width: "70px"}}>
                                      <TextField defaultValue={id} 
                                      onChange = {(event) => handleChangeField(index, 'id', event.target.value)}
                                      />
                                    </TableCell>
                                    <TableCell style={{width: "120px"}}>
                                      <TextField className = {classes.projectText} defaultValue={issue} 
                                      onChange = {(event) => handleChangeField(index, 'issue', event.target.value)}
                                      />
                                    </TableCell>
                                    <TableCell style={{width: "80px"}}>
                                      <TextField defaultValue={value} 
                                      onChange = {(event) => handleChangeField(index, 'value', event.target.value)}
                                      />
                                    </TableCell>
                                    <TableCell style={{width: "70px"}}>
                                      <TextField defaultValue={hours} 
                                      onChange = {(event) => handleChangeField(index, 'hours', event.target.value)}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <TextField style={{width: "200px"}} defaultValue={comments} 
                                      onChange = {(event) => handleChangeField(index, 'comments', event.target.value)}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))
                              }
                            </TableBody>
                          </Table>
                        </TableContainer> 
                        </div>
                <DropzoneArea acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                      filesLimit={5}
                                      onChange={handleFiles}
                                      disableRejectionFeedback
                                      showPreviewsInDropzone={false}
                                      showPreviews={true}
                                      alertSnackbarProps={{anchorOrigin: {vertical: "bottom", horizontal: "center"}}}
                                      maxFileSize={null} //Límite controlado en el evento change. Este limite es para sobreescribir el valor por defecto 2MB
                />
                   <div className = {classes.projects}>
                     <Toolbar style={{justifyContent: "space-between"}}>
                          <Typography className = {classes.legend}>Media files</Typography>
                        </Toolbar>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{width: "3.5rem"}}/>
                                <TableCell>{"File"}</TableCell>
                                <TableCell>{"Size"}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                fileList.map(({key, id, size},index) => (
                                  <TableRow key={id}>
                                    <TableCell style={{width: "3.5rem"}} component={IconButton}
                                               onClick={() => removeFile(key)}
                                      >
                                      <Delete color={"secondary"}/>
                                    </TableCell>
                                    <TableCell >
                                      <Typography>{id}</Typography>
                                    </TableCell>
                                    <TableCell >
                                      <Typography>{size}</Typography>
                                    </TableCell>
                                  </TableRow>
                                ))
                              }
                            </TableBody>
                          </Table>
                        </TableContainer> 
                        </div>
                </div>
                </div>
               </div>  
               </Paper>
    </>
  )
}
