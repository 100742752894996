import AppBar from '@material-ui/core/AppBar';
import React, {useState, useContext} from "react";
import {Auth} from "aws-amplify";
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import LanguageIcon from '@material-ui/icons/Language';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpen from '@material-ui/icons/MenuOpen';
import ExitIcon from '@material-ui/icons/ExitToApp';
import ToolBarCSS from './ToolBarCSS.js'
import Typography from '@material-ui/core/Typography';
import Language from './Language/Language'
import Search from './Search'
import Avatar from '@material-ui/core/Avatar';
import {useTranslation} from "react-i18next";
import AuthContext from "../../context/AuthContext";
import {Box} from "@material-ui/core";

export default function ToolBar({searchField, setSearchField, parentCallback}) {

  const classes = ToolBarCSS();
  const [anchorEl, setAnchorEl] = useState(null);
  const {user, setUser} = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);

  const handleClickLanguage = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCallback = (childData) => {
    if (!childData.anchorEl) {
      setAnchorEl(childData.anchorEl)
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true)
    parentCallback({open: true})
  };

  const handleDrawerClose = () => {
    setOpen(false);
    parentCallback({open: false})
  };



  async function signOut() {
    try {
      await Auth.signOut();
      window.location.reload()
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  return (
    <>
      <AppBar className={classes.root} color={user ? 'primary' : 'transparent'} position={user ? 'sticky' : 'fixed'}
              style={user ? {} : {marginTop: "50px"}}>
        <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
           [classes.hide]: open,
            })}
          >
           <MenuIcon />
          </IconButton>
          <IconButton 
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerClose}
              edge="start"
              className={clsx(classes.menuButton, {
             [classes.hide]: !open,
              })}
          >
            <MenuOpen/> 
          </IconButton>
          <Box flexDirection={"column"} display={"flex"} flexGrow={"1"} alignItems={"flex-end"}>
            {
              user ?
              <Box alignItems={"center"} display={"flex"}>
                 {/* {user ? <Search searchField={searchField} setSearchField={setSearchField}/> : null} */}
                 {/* <IconButton size={"medium"} className={classes.toolbarButton} aria-label="menu"
                              onClick={handleClickLanguage}>
                    <LanguageIcon/>
                  </IconButton> */}
                  <Typography noWrap>
                    {/* {user.username} */}
                  </Typography>
                  <Avatar 
                  // src={user['custom:avatar']} 
                  className={classes.avatar}/>
                  {
                user ?
                  <IconButton className={classes.toolbarButton} aria-label="menu" onClick={signOut}>
                    <ExitIcon/>
                  </IconButton> :
                  null
              }
                </Box> :
                null
            }
          </Box>
        </Toolbar>
      </AppBar>
      <Language anchorEl={anchorEl} parentCallback={handleCallback}/>
    </>
  );
}
