const style = {
  root: {
    flexGrow: 1,
    backgroundColor: '#F1F1F1'
  },
  paper: {
    padding: '15px',
    margin: 'auto',
    backgroundColor: 'transparent',
  },
  image: {
    width: 128,
    height: 128,
    marginLeft: '20px'
  },
  img: {
    margin: 'auto',
    marginTop: '15px',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  tittle: {
    fontWeight: 'bold',
    marginTop: '15px',
    color: '#003496'
  },
  tittleLeft: {
    fontWeight: 'bold',
    marginTop: '15px',
    marginLeft: '15px',
    color: '#003496'
  },
  form: {
    marginLeft: '40px'
  },
  rootGrid:{
    display: 'flex',
    flexWrap: 'wrap',
   // justifyContent: 'space-around',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: '10px',
    backgroundColor: '#F1F1F1',
    minHeight: '300px'
  },
  rootVideo: {
    minHeight: '300px',
    padding: '10px',
    backgroundColor: '#F1F1F1'
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',

  },
  tile: {
    margin: '15px',
    minHeight: '250px'
  },
  viewer:{
    marginLeft: '40px'
  }
}

export default style