import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import {Grid, Paper} from '@material-ui/core';

const useStyles = makeStyles((theme) => (style));

export default function Home() {

  const classes = useStyles();
  
  return (
    <div className={classes.root}>
              <img src='https://www.enzymeadvisinggroup.com/hubfs/Enzyme%20Web%202020/logo_enzyme_web.png' alt="Logo" />
    </div>
  )
}
