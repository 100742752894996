import Popover from '@material-ui/core/Popover';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LanguageCSS from './LanguageCSS';
import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';
import LanguageContext from "../../../context/LanguageContext"
import constants from "../../../constants"

const useStyles = makeStyles((theme) => (LanguageCSS));

export default function ToolBar({anchorEl, parentCallback}) {

  const [t, i18n] = useTranslation();
  const {language, setLanguage} = useContext(LanguageContext);

  const classes = useStyles();
  const languagesList = constants.languages;

  const handleClose = () => {
    parentCallback({anchorEl: null})
  };

  const handleLanguageChange = (sLanguage) => {
    i18n.changeLanguage(sLanguage);
    setLanguage(sLanguage);
  };

  var open = Boolean(anchorEl);

  return (
    <div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component={"ul"}>
          {languagesList.map(({id, value, flag}) => (
            <ListItem component={"div"} key={id} button onClick={() => {handleLanguageChange(id)}}>
              <ListItemIcon>
                <img src={flag} width='20px' height='20px'/>
              </ListItemIcon>
              <ListItemText primary={value} className={classes.textPopOver}/>
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  )
}
