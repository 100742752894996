import {makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
   root: {
     padding: '30px',
     backgroundColor: '#F1F1F1'
   }

  })
);
