import React, {useContext} from "react";
import "./App.css";
//import "./i18n/i18n"
import theme from "./Theme"
import AuthUI from "./components/AuthUI/AuthUI"
import {LanguageProvider} from "./context/LanguageContext"
import {AuthProvider} from "./context/AuthContext";
import {ThemeProvider} from '@material-ui/core/styles';
import {AlertProvider} from "./context/AlertContext";

function App() {

  return (
    <ThemeProvider theme={theme}>
      <AlertProvider>
        <AuthProvider>
          <LanguageProvider>
              <AuthUI/>
          </LanguageProvider>
        </AuthProvider>
      </AlertProvider>
    </ThemeProvider>
  );
}

export default App;
