import {makeStyles} from '@material-ui/core/styles';

const ToolBarCSS = makeStyles((theme) => ({

  root: {
    // boxShadow: 'none'
  },

  menuButton: {
    marginRight: 10,
  },
  hide: {
    display: 'none',
  },
  toolbarButton: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    margin: '10px',
    marginBottom: theme.spacing(1),
    width: theme.spacing(4),
    height: theme.spacing(4)
  },

  avatar: {
    margin: '10px',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  tab: {
    marginRight: '2.5rem',
    "& .MuiTab-textColorInherit.Mui-selected": {
      opacity: 1,
      color: theme.palette.secondary.main,
      fontWeight: 'bold'
    }
  }

}));

export default ToolBarCSS
