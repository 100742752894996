import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {Typography, List, ListItem, ListItemText, Box} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import ButtonBase from '@material-ui/core/ButtonBase';
import Carousel from 'react-material-ui-carousel'
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ReactPlayer from 'react-player/lazy'
import {Storage} from 'aws-amplify';
import { LocalConvenienceStoreOutlined } from '@material-ui/icons';
import ImageViewer from 'react-simple-image-viewer';

const useStyles = makeStyles((theme) => (style));

export default function BlockForm(props) {

  const classes = useStyles();
  const [data, setData] = React.useState();
  const [images, setImages] = React.useState([]);
  const [currentImage, setCurrentImage] = React.useState(0);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);


  React.useEffect(() =>  {
   var dataAux = props.data
   setData(dataAux)
   getImages(props.data.id + '/' + props.data.version + '/')
  }, [props.data]);

  const handleOpen = (event) => {
    window.open(event.target.innerText)
  }

  const getImages = async (path) => {

    const list = await Storage.list(path) 
    var imagesAux = []
    for (var i = 0; i < list.length; i++){
      if (list[i].size !== 0){
      const imageS3 = await Storage.get(list[i].key); 
      imagesAux.push(imageS3)
      }
    }
    setImages(imagesAux)
  }

  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
    props.hide(false)
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
    props.hide(true)
  };

  return (
    <>
      {isViewerOpen && (
          <ImageViewer
            className={classes.viewer}
            disableScroll={ true }
            src={ images }
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.8)",
              marginTop: '50px',
              marginLeft: '70px',
              width: 'calc(100% - 70px)'
            }}
            currentIndex={ currentImage }
            closeOnClickOutside={ false }
            onClose={ closeImageViewer }
          />
        )}
      {!isViewerOpen && (    
     <Carousel interval = {9999999999}>
     {data?   
      <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item>
{/*         <ButtonBase className={classes.image}>
              <img className={classes.img} alt="complex" src={data.thumbnail?data.thumbnail:image} />
            </ButtonBase> */}

          </Grid>
          <Grid item  sm container className={classes.form} spacing={3}>
            <Grid item xs={9} container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1" className={classes.tittle}>
                  {data.name + '   (' + data.id + ')'}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {data.description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                   {"Repositorio: " + data.repositoryType}
                </Typography>
                <Typography variant="body2">
                <Link  onClick={handleOpen} color="secondary">
                   {data.repository}
                </Link>
                </Typography>
                {data.readme &&
              <div>   
                <Typography gutterBottom variant="subtitle1" className={classes.tittle}>
              Readme
              </Typography>
              <Typography variant="body2" gutterBottom>
                  {data.readme}
              </Typography>
              </div>}
              {data.instructions &&
              <div>   
                <Typography gutterBottom variant="subtitle1" className={classes.tittle}>
              Instructions
              </Typography>
              <Typography paragraph ={true} variant="body2" gutterBottom style={{whiteSpace: 'pre-line'}}>
                  {data.instructions}
              </Typography>
              </div>}
              </Grid>
            </Grid>
            <Grid item xs={3}>
              {data.projects &&
              <div>
              <Typography gutterBottom variant="subtitle1" className={classes.tittle}>
              Projects
              </Typography>

              <List dense={true}>
               {data.projects.map((item, index) => (
                <ListItem>
                  <ListItemText
                    primary= {item.id + ' - ' + item.issue}
                    secondary={
                      <div>
                     
                      <Typography variant="body2">{'Activation Cost (€): ' + item.cost}</Typography>  
                      <Typography variant="body2">{'Hours: ' + item.hours}</Typography> 
                      <Typography variant="body2">{'Comments: ' + item.comments}</Typography> 
                      </div>
                      }
                  />
                </ListItem>
               ))}
            </List>
            </div>}
            {data.cloud &&
              <div>
              <Typography gutterBottom variant="subtitle1" className={classes.tittle}>
              Environments
              </Typography>
              <List dense={true}>
               {data.cloud.map((item, index) => (
                <ListItem>
                  <ListItemText
                    primary= {item.split(' ')[0]}
                   // secondary={item.split(/ (.+)/)[1]}
                  />
                </ListItem>
               ))}
            </List>
            </div>}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>:null}
    <div className={classes.rootGrid}>
      <Typography gutterBottom variant="subtitle1" className={classes.tittleLeft}>
         Media
     </Typography>
     {images?  
      <GridList className={classes.gridList} cols={2.5} spacing={20}>
        {images.map((item, index) => (
          <GridListTile className={classes.tile} key={index}>
            <img alt="" src={item} onClick={ () => openImageViewer(index) } key={index}/>
            </GridListTile>
        ))}
        </GridList>:null}
    </div>
{/*     <div className={classes.rootVideo}>
    <Typography gutterBottom variant="subtitle1" className={classes.tittleLeft}>
         Videos
     </Typography>
     {data && data.videos?
       <div>  
       {data.videos.map((videoTile,index) => ( 
           <ReactPlayer url={videoTile} />
         ))} 
       </div>:null} 
    </div> */}
    </Carousel>
     )}
    </>
  )
}
