import React, {useContext} from "react";
import AuthContext from "../../context/AuthContext";
import AlertContext from "../../context/AlertContext";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import useStyle from "./style";
import Home from '../../pages/Home/index'
import BuildingBlocks from '../../pages/BuildingBlocks/index'
import Report  from "../../pages/Report/index";

export default React.memo(({indexList, searchField}) => {
  const classes = useStyle();
  const {user} = useContext(AuthContext);
  const {message, severity, visible, hideAlert} = useContext(AlertContext);


  return (
      <div className = {classes.root}>
        {
        //indexList === 0 ? <Home/>:
        indexList === 0 ? <BuildingBlocks/>: 
        indexList === 1 ? <Report/>: 
        indexList}
        <Snackbar style={{maxWidth: 600}} open={visible} autoHideDuration={6000} onClose={hideAlert}>
          <Alert variant={"filled"} severity={severity} onClose={hideAlert}>
            {message}
          </Alert>
        </Snackbar>
      </div>
  );
});
