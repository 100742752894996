/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBlock = /* GraphQL */ `
  query GetBlock($id: ID!) {
    getBlock(id: $id) {
      id
      name
      description
      family
      hive
      type
      year
      thumbnail
      license
      versions
      createdAt
      updatedAt
    }
  }
`;
export const listBlocks = /* GraphQL */ `
  query ListBlocks(
    $filter: ModelblockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        family
        hive
        type
        year
        thumbnail
        license
        versions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
