import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import Loader from "../../components/Loader/index";
import Table from "../../components/Table/index";
import Form from "../../components/Form/index";
import {Button} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FilterIcon from '@material-ui/icons/FilterList'
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import {deleteBlock} from '../../graphql/mutations';

const useStyles = makeStyles((theme) => (style));

export default function Template() {

  const classes = useStyles();
  const [showLoader, setShowLoader] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const [reload, setReload] = React.useState(true);
  const [row, setRow] = React.useState({});
  const [data, setData] =React.useState([])
  const [filtering, setFiltering] =  React.useState(false);

 
  React.useEffect(() =>  {
    if (reload === true){
      setShowLoader(true)
      getBuildingBlocks()
    } 
  }, [reload]);

  var columns = [
    {title: "Year",  field: "year", defaultSort: 'desc'}, 
    {title: "Family", width: "10%", field: "family", defaultSort: 'asc', lookup: { AI: 'AI', Cognitive: 'COGNITIVE', Process: 'PROCESS', SAP: 'SAP' ,Methodologies: 'METHODOLOGIES' }}, 
     {title: "Building block",  field: "name", defaultSort: 'asc'},
     {title: "Type", field: "type", lookup: { ABAP: 'ABAP', Algorithm: 'Algorithm' , API: 'API', Container: 'Container', Library: 'Library' , Program: 'Program', Process: 'Process',   UI: 'UI', Workflow: 'Workflow'}},
     {title: "Hive", field: "hive"},
     {title: "Modified at", field: "updatedAt", type: 'datetime', filtering: false},
   ]

   const handleActions = async (data) => {
    if (data.type === 'delete'){
      setReload(false)
      var block = {
        id: data.data.id
      }
      await API.graphql(graphqlOperation(deleteBlock, {input: block}));
      setReload(true)
    }
    if (data.type === 'edit'){
      setReload(false)
      setRow(data.data)
      setShowForm(true)
    }

    if (data.type === 'row'){
      
    }

  }

  const getBuildingBlocks = async () => {
    
    var buildingBlocksResp = await API.graphql({ query: queries.listBlocks });
    var buildingBlocks = buildingBlocksResp.data.listBlocks.items

    for (var i = 0; i < buildingBlocks.length; i++){
      for (var j = 0; j < buildingBlocks[i].versions.length; j++){
        var bbVersion = JSON.parse(buildingBlocks[i].versions[j])
        if (bbVersion.status === 'Default') {
          buildingBlocks[i].version = bbVersion.version
          buildingBlocks[i].repositoryType = bbVersion.repositoryType
          buildingBlocks[i].repository = bbVersion.repository
          buildingBlocks[i].readme = bbVersion.readme
          buildingBlocks[i].instructions = bbVersion.instructions
          buildingBlocks[i].status = bbVersion.status
          buildingBlocks[i].cloud = bbVersion.cloud
          buildingBlocks[i].projects = bbVersion.projects
        } 
      }
    }

    setData(buildingBlocks)
    setShowLoader(false)
  }

  const handleFilters = () => {
    if (filtering === true){
      setFiltering(false)
    } else {
      setFiltering(true)
    }
  }

  const onCreateBB = () => {
    setRow({})
    setShowForm(true)
    setReload(false)
  }

  const handleReturn = () => {
    setShowForm(false)
    setReload(true)
  }


  const handleReport = () => {
    setShowForm(false)
    setReload(true)
  }

  return (
    <div className={classes.root}>
     <Loader show = {showLoader}/>
     {!showForm?
     <div>
     <div className={classes.buttonGrid}>
     <Button
        variant="contained"
        color="secondary"
        onClick={handleFilters}
        className={classes.button}
        startIcon={<FilterIcon />}
        >
        Show/hide filters
      </Button>
     <Button
        variant="contained"
        color="secondary"
        onClick={onCreateBB}
        className={classes.button}
        startIcon={<AddIcon />}
        >
        New Building Block
      </Button>
      </div>
     <Table 
        edit = {true} //es editable
        delete = {true} // se puede borrar
        columns = {columns} // config columnas
        size = {10}
        filtering = {filtering}
        data = {data} // datos
        onActionClick={handleActions} // handle acciones: devuelve type (edit o delete) y los datos de la fila
      />  
      </div>:
      <Form 
        data = {row}
        onReturn = {handleReturn}
      />}       
    </div>
  )
}
